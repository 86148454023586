import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import Rental from '../components/rental';

class Contacts extends Component {
  render() {
    return <Rental lang="rus" />;
  }
}

const LandingPageWithFirebase = withFirebase(withTrainings(Contacts));

export default () => (
  <Layout
    title={'Прокат шосейних велосипедів у Києві'}
    description={
      'Наші велосипеди завжди в чудовому стані й готові до гонки або напруженого тренування будь-якої миті'
    }
  >
    <LandingPageWithFirebase />
  </Layout>
);
